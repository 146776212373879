class BRCheckbox {
  constructor(name, component) {
    this.name = name
    this.component = component
    this._setBehavior()
  }

  _setBehavior() {
    // this._setIndeterminatedBehavior()
    this._setMasterBehavior()
    this._setChildBehavior()
  }

  _setMasterBehavior() {
    this.component.querySelectorAll('input[data-master]').forEach((master) => {
      master.addEventListener('click', () => {
        if (master.hasAttribute('indeterminate')) {
          master.checked = true
          this.component.querySelectorAll('label').forEach((label) => {
            label.innerHTML = 'Desselecionar tudo'
          })
          master.removeAttribute('indeterminate')
        }
        document
          .querySelectorAll(
            `.br-checkbox input[data-child="${master.getAttribute(
              'data-master'
            )}"]`
          )
          .forEach((child) => {
            if (master.checked) {
              master.nextElementSibling.innerHTML = 'Desselecionar tudo'
              if (!child.checked) {
                child.checked = true
              }
            } else {
              master.nextElementSibling.innerHTML = 'Selecionar tudo'
              if (child.checked) {
                child.checked = false
              }
            }
          })
      })
    })
  }

  _setChildBehavior() {
    this.component.querySelectorAll('input[data-child]').forEach((child) => {
      child.addEventListener('click', () => {
        if (this._isAllChildChecked(child.getAttribute('data-child'))) {
          document
            .querySelectorAll(
              `.br-checkbox input[data-master="${child.getAttribute(
                'data-child'
              )}"]`
            )
            .forEach((master) => {
              master.checked = true
              master.nextElementSibling.innerHTML = 'Desselecionar tudo'
              master.removeAttribute('indeterminate')
            })
        } else if (
          this._isAllChildUnchecked(child.getAttribute('data-child'))
        ) {
          document
            .querySelectorAll(
              `.br-checkbox input[data-master="${child.getAttribute(
                'data-child'
              )}"]`
            )
            .forEach((master) => {
              master.checked = false
              master.nextElementSibling.innerHTML = 'Selecionar tudo'
              master.removeAttribute('indeterminate')
            })
        } else {
          document
            .querySelectorAll(
              `.br-checkbox input[data-master="${child.getAttribute(
                'data-child'
              )}"]`
            )
            .forEach((master) => {
              master.checked = true
              master.nextElementSibling.innerHTML = 'Selecionar tudo'
              master.setAttribute('indeterminate', '')
            })
        }
      })
    })
  }

  _isAllChildChecked(childName) {
    let allChecked = true
    document
      .querySelectorAll(`.br-checkbox input[data-child="${childName}"]`)
      .forEach((child) => {
        if (!child.checked) {
          allChecked = false
        }
      })
    return allChecked
  }

  _isAllChildUnchecked(childName) {
    let allUnchecked = true
    document
      .querySelectorAll(`.br-checkbox input[data-child="${childName}"]`)
      .forEach((child) => {
        if (child.checked) {
          allUnchecked = false
        }
      })
    return allUnchecked
  }
}

const checkboxList = []
for (const brCheckbox of window.document.querySelectorAll('.br-checkbox')) {
  checkboxList.push(new BRCheckbox('br-checkbox', brCheckbox))
}

export default BRCheckbox
